import React from 'react';

// import { isMobile } from 'react-device-detect';
import ReactDOM from 'react-dom/client';

import { isDefined } from '~shared/lib';

import './shared/api';
import './app/styles/index.scss';
import { App } from './app/index';
import reportWebVitals from './report-web-vitals';

Telegram.WebApp.expand();
Telegram.WebApp.disableVerticalSwipes();
Telegram.WebApp.setHeaderColor('#1c1c2c');

const rootElement = document.getElementById('root');
const root = isDefined(rootElement) ? ReactDOM.createRoot(rootElement) : null;

root?.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
void reportWebVitals();
