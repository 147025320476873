import type React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useStore } from 'app/store';
import * as dateFns from 'date-fns';
import { observer } from 'mobx-react';
import { generatePath, Link } from 'react-router-dom';

import { AuctionRewardCurrency, GetAuctionResponse } from '~shared/api/client';
import { TelegramStarIcon } from '~shared/assets/icons';
import { ArrowRightIcon } from '~shared/assets/icons/arrows';
import { tonIconImage } from '~shared/assets/icons/currency';
import { formatAmount, isDefined } from '~shared/lib';
import { AppRoutes } from '~shared/model';
import { Amount, AnimatedCounter, Button, Hedgehog } from '~shared/ui';

import { getTimerValues } from '../lib';

interface NordicAuctionLinkProps {
  auction: GetAuctionResponse;
}

export const NordicAuctionLink: React.FC<NordicAuctionLinkProps> = observer(({ auction }) => {
  const {
    userStore: { user, openConnectWalletDrawer },
    nordicAuction: { openPurchaseEntryDrawer },
  } = useStore();

  const timerRef = useRef<NodeJS.Timer>();

  const [secondsRemaning, setSecondsRemaining] = useState(0);

  const timerValues = getTimerValues(secondsRemaning);

  const isRestrictedAccess = auction.is_paid_entrance && auction.entry_state === GetAuctionResponse.entry_state.CLOSED;

  const handleCheckAccess = useCallback(
    (event: React.MouseEvent) => {
      const isTonWalletConnected = isDefined(user) && isDefined(user.ton_wallet_address);

      if (isRestrictedAccess && isTonWalletConnected) {
        event.preventDefault();
        openPurchaseEntryDrawer(auction);
      } else if (!isTonWalletConnected) {
        event.preventDefault();
        openConnectWalletDrawer();
      }
    },
    [isRestrictedAccess, openPurchaseEntryDrawer, auction, user, openConnectWalletDrawer]
  );

  useEffect(() => {
    clearInterval(timerRef.current);
    const handleTimerUpdate = (): void => {
      const seconds = dateFns.differenceInSeconds(
        isDefined(auction) &&
          isDefined(auction.end_time_at) &&
          auction.end_time_at > Math.floor(new Date().getTime() / 1000)
          ? new Date(auction.end_time_at * 1000)
          : new Date(),
        new Date()
      );

      setSecondsRemaining(Math.trunc(seconds));
    };

    handleTimerUpdate();
    timerRef.current = setInterval(handleTimerUpdate, 1000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [timerRef, auction]);

  return (
    <Button
      className="flex justify-between overflow-hidden rounded-xl bg-white/10 backdrop-blur-lg"
      variant="wrapper"
      size="wrapper"
      asChild
    >
      <Link to={generatePath(AppRoutes.NordicAuction, { auctionId: auction.id ?? '' })} onClick={handleCheckAccess}>
        <div className="flex items-center justify-start space-x-3 px-3 py-2">
          <Hedgehog className="h-[50px] w-[46px] shrink-0" color="green" showGlowing={false} />

          <div className="flex flex-col space-y-1">
            <Amount
              className="justify-start [&>*]:text-base [&>img]:mr-1 [&>img]:size-5"
              logo={auction.reward_currency === AuctionRewardCurrency.TON ? tonIconImage : undefined}
              value={formatAmount(parseFloat(auction.reward))}
            />

            <div className="flex items-center font-normal">
              {isDefined(auction.current_price !== auction.start_price) ? (
                <div className="flex items-center space-x-1">
                  <span>Current bid</span>

                  <Amount
                    className="justify-start [&>*]:text-base [&>*]:font-normal [&>img]:mr-1 [&>img]:size-5"
                    value={formatAmount(parseFloat(auction.current_price))}
                  />
                </div>
              ) : (
                <span>0 bids</span>
              )}
            </div>

            <div className="flex items-start space-x-1">
              {timerValues.days[0] + timerValues.days[1] > 0 && (
                <div className="flex items-center">
                  <AnimatedCounter className="h-5" value={timerValues.days[0]} />
                  <AnimatedCounter className="h-5" value={timerValues.days[1]} />
                  <span>d</span>
                </div>
              )}
              {timerValues.hours[0] + timerValues.hours[1] > 0 && (
                <div className="flex flex-col items-center space-y-1">
                  <div className="flex items-center">
                    <AnimatedCounter className="h-5" value={timerValues.hours[0]} />
                    <AnimatedCounter className="h-5" value={timerValues.hours[1]} />
                    <span>h</span>
                  </div>
                </div>
              )}
              {timerValues.minutes[0] + timerValues.minutes[1] > 0 && (
                <div className="flex items-center">
                  <AnimatedCounter className="h-5" value={timerValues.minutes[0]} />
                  <AnimatedCounter className="h-5" value={timerValues.minutes[1]} />
                  <span>m</span>
                </div>
              )}
              <div className="flex items-center">
                <AnimatedCounter className="h-5" value={timerValues.seconds[0]} />
                <AnimatedCounter className="h-5" value={timerValues.seconds[1]} />
                <span>s</span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex h-full w-[66px] items-center justify-center bg-blue text-white">
          {isRestrictedAccess ? <TelegramStarIcon className="size-6" /> : <ArrowRightIcon className="size-6" />}
        </div>
      </Link>
    </Button>
  );
});
