/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* eslint-disable */
import type { AuctionRewardCurrency } from './AuctionRewardCurrency';
import type { AuctionState } from './AuctionState';
export type GetAuctionResponse = {
  id?: string;
  /**
   * Auction title
   */
  title: string;
  /**
   * Start price of the auction
   */
  start_price: string;
  /**
   * Current price of the auction
   */
  current_price: string;
  /**
   * Step price of the auction
   */
  step_price: string;
  /**
   * Reward of the auction
   */
  reward: string;
  /**
   * Reward currency of the auction
   */
  reward_currency: AuctionRewardCurrency;
  /**
   * End time of the auction
   */
  end_time_at: number;
  /**
   * Creation time of the auction
   */
  created_at: number;
  /**
   * State of the auction
   */
  state: AuctionState;
  /**
   * Is paid entrance of the auction
   */
  is_paid_entrance: boolean;
  /**
   * Entry price of the auction
   */
  entry_price: string;
  /**
   * State of the entrance
   */
  entry_state?: GetAuctionResponse.entry_state;
  /**
   * Lifetime of the auction in seconds
   */
  readonly lifetime_seconds: number;
};
export namespace GetAuctionResponse {
  /**
   * State of the entrance
   */
  export enum entry_state {
    OPEN = 'open',
    CLOSED = 'closed',
  }
}
