import type React from 'react';
import { useCallback, useState } from 'react';

import { useStore } from 'app/store';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';

import { WidgetsAuctionsApiService } from '~shared/api/client';
import { TonIcon } from '~shared/assets/icons/currency';
import tgStar from '~shared/assets/images/_tg-star.png';
import { isDefined } from '~shared/lib';
import { Button, Drawer, DrawerContent, DrawerDescription, DrawerFooter, DrawerHeader, DrawerTitle } from '~shared/ui';

export const PurchaseAuctionEntryDrawer: React.FC = observer(() => {
  const {
    projectStore: { project },
    userStore: { user },
    nordicAuction: { purchaseEntryDrawerProps, closePurchaseEntryDrawer },
  } = useStore();

  const [isLoading, setIsLoading] = useState(false);

  const handleOpenChange = useCallback(
    (newOpen: boolean) => {
      if (!newOpen) {
        closePurchaseEntryDrawer();
        setIsLoading(false);
      }
    },
    [closePurchaseEntryDrawer]
  );

  const handlePurchase = useCallback(async () => {
    try {
      if (isNil(purchaseEntryDrawerProps) || isNil(user) || isNil(user.id) || isNil(project) || isNil(project.id))
        return;
      setIsLoading(true);
      const purchaseAuctionEntryResponse =
        await WidgetsAuctionsApiService.buyAnAuctionPassApiV2WidgetAuctionsAuctionIdBuyAnAuctionPassPost(
          purchaseEntryDrawerProps.auction.id ?? '',
          user.id
        );
      Telegram.WebApp.openInvoice(purchaseAuctionEntryResponse.payment_link_url, () => {});
      closePurchaseEntryDrawer();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [closePurchaseEntryDrawer, purchaseEntryDrawerProps, user, project]);

  return (
    <Drawer open={isDefined(purchaseEntryDrawerProps)} onOpenChange={handleOpenChange}>
      <DrawerContent>
        <DrawerHeader className="space-y-4">
          <TonIcon className="mx-auto size-20" />
          <DrawerTitle>TON Auction</DrawerTitle>
          <DrawerDescription className="flex items-center justify-center space-x-1">
            To participate in this auction, you need to pay
            <span className="ml-1 inline-flex items-center space-x-1">
              <img className="size-4" src={tgStar} alt="" />
              <span>{purchaseEntryDrawerProps?.auction.entry_price}</span>
            </span>
          </DrawerDescription>
        </DrawerHeader>

        <DrawerFooter>
          <Button buttonColor="blue" isLoading={isLoading} onClick={handlePurchase}>
            Pay
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
});
