import type React from 'react';
import { useCallback, useState } from 'react';

import { useStore } from 'app/store';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';

import { WidgetsAuctionsApiService } from '~shared/api/client';
import { InfoIcon } from '~shared/assets/icons';
import {
  Amount,
  Button,
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '~shared/ui';

export const PlaceAuctionBetDrawer: React.FC = observer(() => {
  const {
    projectStore: { project, getProject },
    userStore: { user, getUser },
    nordicAuction: { closePlaceBetDrawer, auction, isPlaceBetDrawerOpen },
  } = useStore();

  const [isLoading, setIsLoading] = useState(false);

  const handleOpenChange = useCallback(
    (newOpen: boolean) => {
      if (!newOpen) {
        closePlaceBetDrawer();
        setIsLoading(false);
      }
    },
    [closePlaceBetDrawer]
  );

  const handleBetSubmit = useCallback(async () => {
    if (isNil(auction) || isNil(auction.id) || isNil(user) || isNil(user.id) || isNil(project) || isNil(project.id))
      return;
    try {
      setIsLoading(true);
      await WidgetsAuctionsApiService.purchaseLotApiV2WidgetAuctionsAuctionIdPurchasePost(auction.id, user.id);
      await getUser();
      await getProject(project.id, user.id);
      closePlaceBetDrawer();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [closePlaceBetDrawer, user, project, getUser, getProject, auction]);

  return (
    <Drawer open={isPlaceBetDrawerOpen} onOpenChange={handleOpenChange} shouldScaleBackground>
      <DrawerContent className="flex flex-col">
        <DrawerHeader className="mb-0 flex flex-col items-center space-y-4">
          <InfoIcon className="size-20" />
          <DrawerTitle className="mt-8">Confirm Your Bid</DrawerTitle>
          <DrawerDescription className="text-white">You're about to place a bid of</DrawerDescription>
        </DrawerHeader>

        <Amount
          className="mt-8 [&>*]:text-2xl [&>img]:mr-1 [&>img]:size-10"
          value={auction?.current_price.toString()}
        />

        <DrawerFooter className="mt-8 flex flex-col space-y-2">
          <Button buttonColor="blue" onClick={handleBetSubmit} isLoading={isLoading}>
            Confirm
          </Button>

          <Button
            className="rounded-xl border border-white/10 bg-white/[4%]"
            variant="wrapper"
            onClick={closePlaceBetDrawer}
          >
            Cancel
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
});
