import React from 'react';

import { Slot } from '@radix-ui/react-slot';
import cn from 'classnames';
import { type HTMLMotionProps, motion, AnimatePresence } from 'framer-motion';
import { isNil } from 'lodash';
import {
  Controller,
  type ControllerProps,
  FormProvider,
  useFormContext,
  type FieldError,
  type FieldValues,
  type FieldPath,
} from 'react-hook-form';

import { isDefined } from '~shared/lib';
import { Label } from '~shared/ui';

import type * as LabelPrimitive from '@radix-ui/react-label';

const Form = FormProvider;

type FormFieldContextValue = {
  name: string;
} | null;

const FormFieldContext = React.createContext<FormFieldContextValue>(null);

const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  ...props
}: ControllerProps<TFieldValues, TName>): React.JSX.Element => {
  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  );
};

const useFormField = (): {
  invalid: boolean;
  isDirty: boolean;
  isTouched: boolean;
  isValidating: boolean;
  error?: FieldError;
} & {
  id: string;
  name: string;
  formItemId: string;
  formDescriptionId: string;
  formMessageId: string;
} => {
  const fieldContext = React.useContext(FormFieldContext);
  const itemContext = React.useContext(FormItemContext);
  const { getFieldState, formState } = useFormContext();

  if (isNil(fieldContext)) {
    throw new Error('useFormField should be used within <FormField>');
  }
  if (isNil(itemContext)) {
    throw new Error('useFormField should be used within <FormItem>');
  }

  const fieldState = getFieldState(fieldContext.name, formState);

  const { id } = itemContext;

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState,
    error: fieldState.error,
  };
};

type FormItemContextValue = {
  id: string;
} | null;

const FormItemContext = React.createContext<FormItemContextValue>(null);

const FormItem = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const id = React.useId();

    return (
      <FormItemContext.Provider value={{ id }}>
        <div ref={ref} className={cn('space-y-3', className)} {...props} />
      </FormItemContext.Provider>
    );
  }
);
FormItem.displayName = 'FormItem';

const FormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => {
  const { error, formItemId } = useFormField();

  return (
    <Label
      ref={ref}
      className={cn({ 'text-destructive': isDefined(error) }, className)}
      htmlFor={formItemId}
      {...props}
    />
  );
});
FormLabel.displayName = 'FormLabel';

const FormControl = React.forwardRef<React.ElementRef<typeof Slot>, React.ComponentPropsWithoutRef<typeof Slot>>(
  ({ ...props }, ref) => {
    const { error, formItemId, formDescriptionId, formMessageId } = useFormField();

    return (
      <Slot
        ref={ref}
        id={formItemId}
        aria-describedby={isNil(error) ? `${formDescriptionId}` : `${formDescriptionId} ${formMessageId}`}
        aria-invalid={isDefined(error)}
        {...props}
      />
    );
  }
);
FormControl.displayName = 'FormControl';

const FormDescription = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLParagraphElement>>(
  ({ className, ...props }, ref) => {
    const { formDescriptionId } = useFormField();

    return <p ref={ref} id={formDescriptionId} className={cn(className)} {...props} />;
  }
);
FormDescription.displayName = 'FormDescription';

const FormMessage = React.forwardRef<HTMLParagraphElement, HTMLMotionProps<'p'>>(
  ({ className, children, ...props }, ref) => {
    const { error, formMessageId } = useFormField();
    const body = isDefined(error) ? String(error?.message) : children;

    return (
      <AnimatePresence>
        {isDefined(body) && (
          <motion.p
            ref={ref}
            id={formMessageId}
            className={cn('text-xs text-destructive', className)}
            variants={{
              hide: { opacity: 0, height: '0rem', marginTop: '0rem' },
              show: { opacity: 1, height: 'auto', marginTop: '0.5rem' },
            }}
            initial="hide"
            animate="show"
            exit="hide"
            {...props}
          >
            {body}
          </motion.p>
        )}
      </AnimatePresence>
    );
  }
);
FormMessage.displayName = 'FormMessage';

export { useFormField, Form, FormItem, FormLabel, FormControl, FormDescription, FormMessage, FormField };
