import { makeAutoObservable } from 'mobx';

import {
  type GetAuctionsListResponse,
  WidgetsAuctionsApiService,
  type GetAuctionResponse,
  type GetLotsHistoryResponse,
} from '~shared/api/client';

export class NordicAuctionStore {
  isPlaceBetDrawerOpen: boolean;

  isRulesDrawerOpen: boolean;

  purchaseEntryDrawerProps: { auction: GetAuctionResponse } | null;

  auctions: GetAuctionsListResponse | null;

  auction: GetAuctionResponse | null;

  bets: GetLotsHistoryResponse | null;

  constructor() {
    makeAutoObservable(this, {}, { deep: true, autoBind: true, name: 'nordicAuction' });

    this.isPlaceBetDrawerOpen = false;

    this.isRulesDrawerOpen = false;

    this.purchaseEntryDrawerProps = null;

    this.auctions = null;

    this.auction = null;

    this.bets = null;
  }

  async getAuctions(): Promise<void> {
    try {
      const auctionsResponse = await WidgetsAuctionsApiService.getAuctionsListApiV2WidgetAuctionsGet(true);
      this.auctions = auctionsResponse;
    } catch (error) {
      console.error(error);
    }
  }

  async getAuction(auctionId: string): Promise<void> {
    try {
      const [auctionResponse, betsResponse] = await Promise.all([
        WidgetsAuctionsApiService.getAuctionDetailsApiV2WidgetAuctionsAuctionIdGet(auctionId),
        WidgetsAuctionsApiService.getLotsHistoryApiV2WidgetAuctionsAuctionIdLotsHistoryGet(auctionId),
      ]);

      this.auction = auctionResponse;
      this.bets = betsResponse;
    } catch (error) {
      console.error(error);
    }
  }

  resetAuction(): void {
    this.auction = null;
    this.bets = null;
  }

  openPlaceBetDrawer(): void {
    this.isPlaceBetDrawerOpen = true;
  }

  closePlaceBetDrawer(): void {
    this.isPlaceBetDrawerOpen = false;
  }

  openRulesDrawer(): void {
    this.isRulesDrawerOpen = true;
  }

  closeRulesDrawer(): void {
    this.isRulesDrawerOpen = false;
  }

  openPurchaseEntryDrawer(auction: GetAuctionResponse): void {
    this.purchaseEntryDrawerProps = { auction };
  }

  closePurchaseEntryDrawer(): void {
    this.purchaseEntryDrawerProps = null;
  }
}
