import type React from 'react';
import { useCallback, useState } from 'react';

import { useStore } from 'app/store';
import { observer } from 'mobx-react';

import { InfoIcon } from '~shared/assets/icons';
import { Button, Drawer, DrawerContent, DrawerDescription, DrawerFooter, DrawerHeader, DrawerTitle } from '~shared/ui';

export const AuctionRulesDrawer: React.FC = observer(() => {
  const {
    nordicAuction: { closeRulesDrawer, isRulesDrawerOpen },
  } = useStore();

  const [isLoading, setIsLoading] = useState(false);

  const handleOpenChange = useCallback(
    (newOpen: boolean) => {
      if (!newOpen) {
        closeRulesDrawer();
        setIsLoading(false);
      }
    },
    [closeRulesDrawer]
  );

  return (
    <Drawer open={isRulesDrawerOpen} onOpenChange={handleOpenChange} shouldScaleBackground>
      <DrawerContent className="flex flex-col">
        <div className="flex flex-col overflow-auto">
          <DrawerHeader className="mb-0 flex flex-col items-center space-y-4">
            <InfoIcon className="size-20" />
            <DrawerTitle className="mt-8">How it works</DrawerTitle>
            <DrawerDescription className="text-white">
              This is a type of game where each participant places bids using coins. The unique aspect of this format is
              that each bid deducts a specific number of coins from the player's balance (e.g., 1 coin per bid), and
              these coins are not refunded, even if the participant does not win.
            </DrawerDescription>
          </DrawerHeader>

          <ol className="mt-8 list-decimal pl-4">
            <li>An item is put up for game, and participants take turns placing higher bids.</li>
            <li>Each bid increases the current price of the item, and a coin is deducted from the player's balance.</li>
            <li>The game continues until no new bids are placed within a specified time period.</li>
            <li>
              The winner is the participant who placed the last bid, and they pay the final price of the item, which
              includes their bids.
            </li>
          </ol>

          <DrawerFooter className="mt-8 flex flex-col space-y-2">
            <Button buttonColor="blue" onClick={closeRulesDrawer} isLoading={isLoading}>
              Close
            </Button>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
});
