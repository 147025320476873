import type React from 'react';

import { useStore } from 'app/store';
import { observer } from 'mobx-react';

import { InfoIcon } from '~shared/assets/icons';
import { Button, Drawer, DrawerContent, DrawerDescription, DrawerFooter, DrawerHeader } from '~shared/ui';

export const BalanceResetDrawer: React.FC = observer(() => {
  const {
    userStore: { closeBalanceResetDrawer, isBalanceResetDrawerOpen },
  } = useStore();

  return (
    <Drawer open={isBalanceResetDrawerOpen} shouldScaleBackground>
      <DrawerContent className="flex flex-col" hideCloseButton>
        <div className="flex flex-col overflow-auto">
          <DrawerHeader className="mb-0 flex flex-col items-center space-y-4">
            <InfoIcon className="size-20" />
            <DrawerDescription className="mt-8 text-white">
              It looks like you've been away for 30 days, so your balance has been reset. No worries, though — just
              start over to get back on track!
            </DrawerDescription>
          </DrawerHeader>

          <DrawerFooter className="mt-8 flex flex-col space-y-2">
            <Button buttonColor="blue" onClick={closeBalanceResetDrawer}>
              Close
            </Button>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
});
