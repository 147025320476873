import React, { lazy } from 'react';

import { Navigate, createBrowserRouter } from 'react-router-dom';

import { AppRoutes } from '~shared/model';
import { Layout } from '~widgets/layout';

const BoostPage = lazy(async () => ({ default: (await import('~pages/boost')).BoostPage }));
const DailyBonuses = lazy(async () => ({ default: (await import('~pages/daily-bonuses')).DailyBonuses }));
const FortuneSpin = lazy(async () => ({ default: (await import('~pages/fortune-spin')).FortuneSpin }));
const HedgehogDashPage = lazy(async () => ({ default: (await import('~pages/hedgehog-dash')).HedgehogDashPage }));
const FriendsPage = lazy(async () => ({ default: (await import('~pages/friends')).FriendsPage }));
const GamesPage = lazy(async () => ({ default: (await import('~pages/games')).GamesPage }));
const HomePage = lazy(async () => ({ default: (await import('~pages/home')).HomePage }));
const LevelFriendsPage = lazy(async () => ({ default: (await import('~pages/level-friends')).LevelFriendsPage }));
const ProjectsPage = lazy(async () => ({ default: (await import('~pages/projects')).ProjectsPage }));
const TasksPage = lazy(async () => ({ default: (await import('~pages/tasks')).TasksPage }));
const TermsAndConditionsPage = lazy(async () => ({
  default: (await import('~pages/terms-and-conditions')).TermsAndConditionsPage,
}));
const TopPage = lazy(async () => ({ default: (await import('~pages/top')).TopPage }));
const WalletPage = lazy(async () => ({ default: (await import('~pages/wallet')).WalletPage }));
const NordicAuctionsPage = lazy(async () => ({ default: (await import('~pages/nordic-auctions')).NordicAuctionsPage }));
const NordicAuctionPage = lazy(async () => ({ default: (await import('~pages/nordic-auction')).NordicAuctionPage }));

export const appRouter = createBrowserRouter([
  {
    path: AppRoutes.Root,
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Navigate to={AppRoutes.Home} />,
      },
      {
        path: AppRoutes.Home,
        element: <HomePage />,
      },
      {
        path: AppRoutes.Tasks,
        element: <TasksPage />,
      },
      {
        path: AppRoutes.Friends,
        element: <FriendsPage />,
      },
      {
        path: AppRoutes.AirDrops,
        element: <ProjectsPage />,
      },
      {
        path: AppRoutes.Top,
        element: <TopPage />,
      },
      {
        path: AppRoutes.Games,
        element: <GamesPage />,
      },
    ],
  },
  {
    path: AppRoutes.Root,
    element: <Layout withNavigation={false} />,
    children: [
      {
        path: AppRoutes.LevelFriends,
        element: <LevelFriendsPage />,
      },
      {
        path: AppRoutes.TermsAndConditions,
        element: <TermsAndConditionsPage />,
      },
      {
        path: AppRoutes.DailyBonuses,
        element: <DailyBonuses />,
      },
      {
        path: AppRoutes.Boost,
        element: <BoostPage />,
      },
      {
        path: AppRoutes.Wallet,
        element: <WalletPage />,
      },
      {
        path: AppRoutes.FortuneSpin,
        element: <FortuneSpin />,
      },
      {
        path: AppRoutes.HedgehogDash,
        element: <HedgehogDashPage />,
      },
      {
        path: AppRoutes.NordicAuctions,
        element: <NordicAuctionsPage />,
      },
      {
        path: AppRoutes.NordicAuction,
        element: <NordicAuctionPage />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={{ pathname: AppRoutes.AirDrops, search: window.location.search }} />,
  },
]);
