import * as zod from 'zod';

import { type WithdrawTonValues } from './withdraw-ton-form-values.model';

export const getWithdrawTonSchema = (balance?: string): zod.ZodType<WithdrawTonValues> =>
  zod.object({
    amount: zod.coerce
      .number()
      .gte(0.5, { message: 'Minimal withdraw amount is 0.5 TON' })
      .max(parseFloat(balance ?? '0.0'), 'Insufficient funds'),
  });
