import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useStore } from 'app/store';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';

import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '~features/form';
import { useToast } from '~features/toaster';
import {
  type ApiError,
  type CreateWithdrawTransactionSchema,
  type GetActiveWithdrawTransaction,
  WidgetsTransactionsApiService,
  WithdrawCurrency,
} from '~shared/api/client';
import { TonIcon } from '~shared/assets/icons/currency';
import { formatAmount, isDefined } from '~shared/lib';
import {
  Button,
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  Input,
} from '~shared/ui';

import { getWithdrawTonSchema, type WithdrawTonValues } from '../model';

export const WithdrawTonDrawer: React.FC = observer(() => {
  const {
    userStore: { user, getUser, closeWithdrawTonDrawer, isWithdrawTonDrawerOpen },
  } = useStore();

  const { toast } = useToast();

  const [isWithdrawLoading, setIsWithdrawLoading] = useState(false);

  const [activeWithdrawTransaction, setActiveWithdrawTransaction] = useState<GetActiveWithdrawTransaction | null>(null);

  const withdrawTonSchema = useMemo(() => getWithdrawTonSchema(user?.ton_balance), [user]);

  const withdrawTonForm = useForm<WithdrawTonValues>({
    defaultValues: { amount: '' },
    mode: 'onTouched',
    resolver: zodResolver(withdrawTonSchema),
  });

  const handleClose = useCallback(() => {
    closeWithdrawTonDrawer();
    withdrawTonForm.reset({ amount: '' });
    setIsWithdrawLoading(false);
  }, [withdrawTonForm, closeWithdrawTonDrawer]);

  const handleOpenChange = useCallback(
    (newOpen: boolean) => {
      if (!newOpen) {
        handleClose();
      }
    },
    [handleClose]
  );

  const handleTonWithdrawSubmit = useCallback(
    async (values: CreateWithdrawTransactionSchema) => {
      try {
        if (isNil(user) || isNil(user.id)) return;
        setIsWithdrawLoading(true);
        const activeTransactionResponse =
          await WidgetsTransactionsApiService.createWithdrawTransactionApiV2WidgetUsersUserIdTransactionsWithdrawPost(
            user.id,
            {
              ...values,
              currency: WithdrawCurrency.TON,
            }
          );
        await getUser();
        setActiveWithdrawTransaction(activeTransactionResponse);
      } catch (error) {
        toast({ variant: 'destructive', description: (error as ApiError)?.body?.detail ?? 'Failed to place a bid' });
      } finally {
        setIsWithdrawLoading(false);
      }
    },
    [toast, user, getUser]
  );

  const getActiveWithdrawTransaction = useCallback(async () => {
    try {
      if (isNil(user) || isNil(user.id)) return;
      const activeTransactionResponse =
        await WidgetsTransactionsApiService.getActiveWithdrawTransactionApiV2WidgetUsersUserIdTransactionsWithdrawActiveGet(
          user.id
        );
      setActiveWithdrawTransaction(activeTransactionResponse);
    } catch (error) {
      console.error(error);
    }
  }, [user]);

  useEffect(() => {
    void getActiveWithdrawTransaction();
  }, [getActiveWithdrawTransaction]);

  return (
    <Drawer open={isWithdrawTonDrawerOpen} onOpenChange={handleOpenChange}>
      <DrawerContent>
        <DrawerHeader className="space-y-4">
          <TonIcon className="mx-auto size-20" />
          <DrawerTitle>TON Withdraw</DrawerTitle>
          {isDefined(activeWithdrawTransaction) ? (
            <DrawerDescription>
              Your withdrawal request has been successfully submitted. The funds will be credited to your account within
              30 minutes.
            </DrawerDescription>
          ) : (
            <DrawerDescription>
              Minimal withdrawal amount is 0.5 TON.
              <br />
              Available for withdraw: {formatAmount(parseFloat(user?.ton_balance ?? '0'))} TON
            </DrawerDescription>
          )}
        </DrawerHeader>

        {isDefined(activeWithdrawTransaction) ? (
          <DrawerFooter>
            <Button buttonColor="blue" onClick={handleClose}>
              Close
            </Button>
          </DrawerFooter>
        ) : (
          <Form {...withdrawTonForm}>
            <form className="flex flex-col space-y-5" onSubmit={withdrawTonForm.handleSubmit(handleTonWithdrawSubmit)}>
              <FormField
                name="amount"
                control={withdrawTonForm.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Amount</FormLabel>
                    <FormControl>
                      <Input {...field} type="number" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <DrawerFooter>
                <Button type="submit" buttonColor="blue" isLoading={isWithdrawLoading}>
                  Withdraw
                </Button>
              </DrawerFooter>
            </form>
          </Form>
        )}
      </DrawerContent>
    </Drawer>
  );
});
