import React, { Suspense, useEffect } from 'react';

import { useStore } from 'app/store';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useUserWallet } from '~entities/user';
import { NavPanel } from '~features/nav-panel';
import { StarsBg } from '~features/stars-bg';
import { isDefined } from '~shared/lib';
import { Spinner } from '~shared/ui';
import { AuctionRulesDrawer } from '~widgets/auction-rules-drawer';
import { AutoFarmingBoostActivatedDrawer } from '~widgets/auto-farming-boost-activated-drawer';
import { AutoFarmingWelcomeDrawer } from '~widgets/auto-farming-welcome-drawer';
import { BalanceResetDrawer } from '~widgets/balance-reset-drawer';
import { ConnectWalletDrawer } from '~widgets/connect-wallet-drawer';
import { DailyBonusDrawer } from '~widgets/daily-bonus-drawer';
import { HedgehogDashCompleteDrawer } from '~widgets/hedgehog-dash-complete-drawer';
import { PlaceAuctionBetDrawer } from '~widgets/place-auction-bet-drawer';
import { PurchaseAuctionEntryDrawer } from '~widgets/purchase-auction-entry-drawer';
import { PurchaseGameLivesDrawer } from '~widgets/purchase-game-lives-drawer';
import { ShareDrawer } from '~widgets/share-drawer';
import { SubscriptionCheckDrawer } from '~widgets/subscription-check-drawer';
import { WithdrawTonDrawer } from '~widgets/withdraw-ton-drawer';

interface LayoutProps {
  withNavigation?: boolean;
}

export const Layout: React.FC<LayoutProps> = observer(({ withNavigation = true }) => {
  const {
    jobStore: { latestJob },
    boostsStore: { openAutoFarmingWelcomeDrawer, isAutoFarmingWelcomeDrawerShown },
    userStore: {
      getUser,
      user,
      openBalanceResetDrawer,
      isBalanceResetDrawerShown,
      getResetBalanceStatus,
      balanceResetStatus,
    },
    dailyBonusesStore: { getDailyBonuses, openDailyBonusDrawer, dailyBonuses },
    projectStore: { selectProject, project, rootProject, getProjects },
  } = useStore();
  const navigate = useNavigate();

  useUserWallet();

  useEffect(() => {
    void getUser({ withIp: true });
  }, [getUser]);

  useEffect(() => {
    void getResetBalanceStatus();
  }, [getResetBalanceStatus]);

  useEffect(() => {
    window.Telegram?.WebApp?.BackButton?.onClick(() => {
      navigate(-1);
    });
  }, [navigate]);

  useEffect(() => {
    if (isDefined(user)) {
      void getProjects(user.id ?? '');
    }
  }, [getProjects, user]);

  useEffect(() => {
    if (isDefined(rootProject) && isNil(project)) {
      selectProject(rootProject);
    }
  }, [rootProject, project, selectProject]);

  useEffect(() => {
    if (isDefined(user) && isDefined(user.id)) {
      void getDailyBonuses(user.id);
    }
  }, [user, getDailyBonuses]);

  useEffect(() => {
    if (
      isDefined(dailyBonuses) &&
      dailyBonuses.can_claim &&
      (isAutoFarmingWelcomeDrawerShown || (isDefined(latestJob) && isNil(latestJob.auto_farming_purchased_id))) &&
      (isBalanceResetDrawerShown || (isDefined(balanceResetStatus) && !balanceResetStatus.result))
    ) {
      openDailyBonusDrawer();
    }
  }, [
    dailyBonuses,
    openDailyBonusDrawer,
    latestJob,
    isAutoFarmingWelcomeDrawerShown,
    isBalanceResetDrawerShown,
    balanceResetStatus,
  ]);

  useEffect(() => {
    if (
      isDefined(balanceResetStatus) &&
      balanceResetStatus.result &&
      !isBalanceResetDrawerShown &&
      (isAutoFarmingWelcomeDrawerShown || (isDefined(latestJob) && isNil(latestJob.auto_farming_purchased_id)))
    ) {
      openBalanceResetDrawer();
    }
  }, [
    balanceResetStatus,
    isBalanceResetDrawerShown,
    openBalanceResetDrawer,
    latestJob,
    isAutoFarmingWelcomeDrawerShown,
  ]);

  useEffect(() => {
    if (
      isDefined(latestJob) &&
      isDefined(latestJob.auto_farming_purchased_id) &&
      !isAutoFarmingWelcomeDrawerShown &&
      !isBalanceResetDrawerShown
    ) {
      openAutoFarmingWelcomeDrawer();
    }
  }, [latestJob, openAutoFarmingWelcomeDrawer, isAutoFarmingWelcomeDrawerShown, isBalanceResetDrawerShown]);

  return (
    <div className="flex h-full flex-col">
      <Suspense fallback={<Spinner className="m-auto size-10" />}>
        <Outlet />
      </Suspense>

      {withNavigation && <NavPanel />}

      <StarsBg className="-z-10" />
      <DailyBonusDrawer />
      <SubscriptionCheckDrawer />
      <ShareDrawer />
      <ConnectWalletDrawer />
      <PurchaseGameLivesDrawer />
      <AutoFarmingBoostActivatedDrawer />
      <AutoFarmingWelcomeDrawer />
      <HedgehogDashCompleteDrawer />
      <PlaceAuctionBetDrawer />
      <AuctionRulesDrawer />
      <BalanceResetDrawer />
      <PurchaseAuctionEntryDrawer />
      <WithdrawTonDrawer />
    </div>
  );
});
