/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* eslint-disable */
import type { BuyAuctionPassResponse } from '../models/BuyAuctionPassResponse';
import type { GetAuctionResponse } from '../models/GetAuctionResponse';
import type { GetAuctionsListResponse } from '../models/GetAuctionsListResponse';
import type { GetLotsHistoryResponse } from '../models/GetLotsHistoryResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class WidgetsAuctionsApiService {
  /**
   * Get Auctions List
   * Get auctions list.
   * @param onlyActive Get only active auctions
   * @param limit Limit of items per page
   * @param offset Offset of items
   * @returns GetAuctionsListResponse Successful Response
   * @throws ApiError
   */
  public static getAuctionsListApiV2WidgetAuctionsGet(
    onlyActive: boolean = false,
    limit: number = 100,
    offset?: number
  ): CancelablePromise<GetAuctionsListResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v2/widget/auctions',
      query: {
        only_active: onlyActive,
        limit: limit,
        offset: offset,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Auction Details
   * Get auction details.
   * @param auctionId Auction ID
   * @returns GetAuctionResponse Successful Response
   * @throws ApiError
   */
  public static getAuctionDetailsApiV2WidgetAuctionsAuctionIdGet(
    auctionId: string
  ): CancelablePromise<GetAuctionResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v2/widget/auctions/{auction_id}',
      path: {
        auction_id: auctionId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Get Lots History
   * Get lots history.
   * @param auctionId Auction ID
   * @param limit Limit of items per page
   * @param offset Offset of items
   * @returns GetLotsHistoryResponse Successful Response
   * @throws ApiError
   */
  public static getLotsHistoryApiV2WidgetAuctionsAuctionIdLotsHistoryGet(
    auctionId: string,
    limit: number = 100,
    offset?: number
  ): CancelablePromise<GetLotsHistoryResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v2/widget/auctions/{auction_id}/lots_history',
      path: {
        auction_id: auctionId,
      },
      query: {
        limit: limit,
        offset: offset,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Purchase Lot
   * Purchase auction.
   * @param auctionId Auction ID
   * @param userId
   * @returns void
   * @throws ApiError
   */
  public static purchaseLotApiV2WidgetAuctionsAuctionIdPurchasePost(
    auctionId: string,
    userId: string
  ): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v2/widget/auctions/{auction_id}/purchase',
      path: {
        auction_id: auctionId,
      },
      query: {
        user_id: userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
  /**
   * Buy An Auction Pass
   * Buy an auction pass.
   * @param auctionId Auction ID
   * @param userId
   * @returns BuyAuctionPassResponse Successful Response
   * @throws ApiError
   */
  public static buyAnAuctionPassApiV2WidgetAuctionsAuctionIdBuyAnAuctionPassPost(
    auctionId: string,
    userId: string
  ): CancelablePromise<BuyAuctionPassResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v2/widget/auctions/{auction_id}/buy_an_auction_pass',
      path: {
        auction_id: auctionId,
      },
      query: {
        user_id: userId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
