import { useEffect, useRef } from 'react';

import { isDefined } from '../types';

export interface UsePollingParams {
  callback: () => void;
  interval?: number;
}

export function usePolling({ callback, interval = 500 }: UsePollingParams): void {
  const pollingInterval = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isDefined(pollingInterval.current)) {
      clearInterval(pollingInterval.current);
    }

    callback();
    pollingInterval.current = setInterval(() => {
      callback();
    }, interval);
  }, [callback, interval]);

  useEffect(() => {
    return () => {
      clearInterval(pollingInterval.current);
    };
  }, []);
}
