/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* eslint-disable */
/**
 * Auction reward currency enum
 */
export enum AuctionRewardCurrency {
  GRAPH_DEX_COIN = 'GRAPH_DEX_COIN',
  TON = 'TON',
}
