import React, { forwardRef, useCallback } from 'react';

import { useStore } from 'app/store';
import { type VariantProps, cva } from 'class-variance-authority';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';
import { Link, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { CoinsIcon, QuestionIcon } from '~shared/assets/icons';
import { ChevronLeftIcon } from '~shared/assets/icons/chevrons';
import { isDefined } from '~shared/lib';
import { AppRoutes } from '~shared/model';

import { Button } from '../button';

export interface PageProps extends React.PropsWithChildren, VariantProps<typeof pageVariants> {
  className?: string;
  headerContent?: React.ReactNode;
}

const pageVariants = cva('flex h-full flex-col overflow-auto bg-cover bg-center bg-top px-6 pb-[112px] pt-3', {
  variants: {
    background: {
      blue: 'bg-blue-page',
      red: 'bg-red-page',
      green: 'bg-green-page',
      multicolor: 'bg-multicolor-page',
    },
  },
  defaultVariants: {
    background: 'multicolor',
  },
});

const Page = forwardRef<HTMLDivElement, PageProps>(({ children, background, className, headerContent }, ref) => {
  const navigate = useNavigate();

  const {
    jobStore: { resetJob },
    projectStore: { selectProject, rootProject },
  } = useStore();

  const handleBackToGraphdex = useCallback(() => {
    if (isDefined(rootProject)) {
      resetJob();
      selectProject(rootProject);
    }
  }, [resetJob, rootProject, selectProject]);

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div
      className={twMerge(pageVariants({ background }), isDefined(headerContent) ? 'pt-[75px]' : '', className)}
      ref={ref}
    >
      {isDefined(headerContent) && (
        <div className="fixed left-0 top-0 z-10 grid w-full grid-cols-[64px_1fr_64px] items-center justify-items-center bg-background p-2 text-[18px]">
          <Button className="text-[18px] font-normal text-blue" variant="wrapper" size="wrapper" onClick={handleGoBack}>
            <ChevronLeftIcon className="size-6" />
            Back
          </Button>
          {headerContent}
        </div>
      )}
      {isNil(headerContent) && (
        <div className="mb-8 flex w-full items-center justify-between pb-3">
          <Button
            variant="wrapper"
            className="rounded bg-white/10 p-0 px-2 py-1 text-xs font-semibold text-white"
            onClick={handleBackToGraphdex}
          >
            <Link className="flex items-center" to={AppRoutes.Wallet}>
              <CoinsIcon className="mr-2 size-6 shrink-0" />
              Wallet
            </Link>
          </Button>

          <Button
            variant="wrapper"
            className="rounded border border-white/10 p-0 px-2 py-1 text-xs font-semibold text-white"
            onClick={handleBackToGraphdex}
          >
            <Link className="flex items-center" to={AppRoutes.TermsAndConditions}>
              <QuestionIcon className="mr-2 size-6 shrink-0" />
              Terms and conditions
            </Link>
          </Button>
        </div>
      )}
      {children}
    </div>
  );
});
Page.displayName = 'Page';
const ObservedPage = observer(Page);

export { ObservedPage as Page };
